import { useEffect, useState } from 'react';
import { Menu, Drawer, Typography, Popover } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import styles from './AdvisoryHeader.module.scss';
import { useSideBarMenu } from '../../utils/content/sidebar-menus';
import AppleIcon from '../../assets/applestoreImageBtn.png';
import docupdateWhite from '../../assets/docupdate-white-icon.png'
import PlayStoreIcon from '../../assets/googlePlayStoreBadge.webp';
import { Link } from 'react-router-dom';
import { ReactComponent as MenuFoldIcon } from '../../assets/MenuFoldIcon.svg';
import { ReactComponent as MenuUnFoldIcon } from '../../assets/MenuUnFoldIcon.svg'
import { getProfileInitials } from '../../utils/methods/util-methods';
import useDashboard from '../../Pages/AdvisoryBoardPortal/hooks/useDashboard';
import { useForm } from '../../utils/hooks/useForm';

const AppHeader = () => {
  const { form } = useForm();
  const { initialValues, getUserDetails } = useDashboard(form);
  const userProfileDetail = getProfileInitials(initialValues?.board_firstName || "", initialValues?.board_lastName || "");
  const { MENU_INFO_MOBILE, open, setOpen } = useSideBarMenu();

  useEffect(() => {
    getUserDetails();
  }, [])

  return (
    <div className={`${styles.drawer_setup}`} id='AdvisoryHeader_drawer_setup'>
      <div className='flex justify-between items-center w-full'>
        {open ? <MenuUnFoldIcon onClick={() => setOpen(false)} /> : <MenuFoldIcon onClick={() => setOpen(true)} />}
        <img src={docupdateWhite} alt='docupdate-icon' className='w-full h-full max-w-32 object-contain' />
        <Popover className='ss' trigger={'click'} content={<span className='font-bold'>{initialValues?.board_firstName} {initialValues?.board_lastName}</span>}>
          {userProfileDetail.initials && <div className={`flex w-12 h-12 justify-end items-center bg-[#9873ff] rounded-full`}>
            <div
              className="text-white w-14 h-14 flex items-center justify-center font-bold text-lg"
            >
              {userProfileDetail.initials}
            </div>
          </div>}
        </Popover>
      </div>
      <Drawer
        placement="left"
        closable={false}
        onClose={() => setOpen(false)}
        open={open}
        destroyOnClose
        className={`${styles.drawer_open_bg} max-w-[260px]`}
      >
        {open ? <ArrowLeftOutlined className={`absolute -right-[0.9rem] top-[50%] z-10 text-violet-500 rounded-[25px] p-2 ${styles.drawer_open_icon_bg}`} onClick={() => setOpen(false)} /> : <ArrowRightOutlined className={`absolute -right-[0.9rem] top-[50%] z-10 text-violet-500 rounded-[25px] p-2 ${styles.drawer_open_icon_bg}`} onClick={() => setOpen(false)} />}
        <div className='flex flex-col gap-y-14 w-full h-full'>
          <img src={docupdateWhite} alt='white-logo' />
          <Menu mode='inline' className='bg-transparent w-full h-full border-none flex flex-col gap-y-4 max-h-[422px]' items={MENU_INFO_MOBILE} defaultSelectedKeys={['1']} />
          <div className='flex flex-col gap-y-4'>
            <Typography.Text className='font-urbanist font-bold text-2xl leading-tight'>Download the Prescriber app now</Typography.Text>
            <Link target='_blank' to={'https://apps.apple.com/us/app/prescriber/id6478404244'} className='flex justify-center items-center'>
              <img src={AppleIcon} alt='apple-icon' className='object-contain w-full h-full bg-white rounded-[10.8px]' />
            </Link>
            <Link target='_blank' to={'https://play.google.com/store/apps/details?id=com.impericus.prescriber'} className='flex justify-center items-center'>
              <img src={PlayStoreIcon} alt='playstore-icon' className='object-cover w-full' />
            </Link>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AppHeader;