import { Button, Col, Form, Row, Typography } from 'antd';
import { useForm } from '../../utils/hooks/useForm';
import FloatInput from '../../components/FloatInput/floatInput';
import { useAuth } from '../../utils/hooks/useAuth'; // Import the useAuth hook
import { useCountDown } from '../../utils/hooks/useCountDown';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { allowNumOnly, emailPattenValidation, passwordPatternValidation } from '../../utils/methods/form-validations';
import { useState } from 'react';

const ForgetPasswordV1 = () => {
  const navigate = useNavigate();
  const { form } = useForm();
  const { forgetPassword, verifiedForgetPassEmail, sendResetPassCode, isApiLoading } = useAuth(form);
  const { count, resetCount } = useCountDown(60);
  const [isResetCode, setIsResetCode] = useState(false);

  const onFinishFailed = (errorInfo: any) => { };

  const onFinish = async (values: any) => {
    try {
      await forgetPassword({ email: values.forget_email, password: values?.forget_password, verificationCode: values.verificationCode, resetCount, isLoadingState: true });
    } catch (error) {
      onFinishFailed(error);
    }
  }

  const handleResetCode = () => {
    resetCount();
    setIsResetCode(true);
    form?.submit();
  }

  const handleFinish = async (values: any) => {
    try {
      if (isResetCode) {
        sendResetPassCode({ email: verifiedForgetPassEmail, resetCount, isLoadingState: false });
      }
      else {
        await onFinish(values)
      }
    } catch (error) {
      onFinishFailed(error);
    }
    finally {
      setIsResetCode(false);
    }
  };

  return (
    <div className='my-2 flex flex-col justify-center items-center px-6 py-4 w-full max-w-screen-2xl mx-auto h-[90vh]'>
      <Form
        form={form}
        name='signup'
        onFinish={handleFinish}
        onFinishFailed={onFinishFailed}
        className='my-6 pt-2 flex flex-col bg-custom-gradient w-full max-w-[470px] justify-center items-center rounded-xl mobile-m:rounded-3xl mobile-m:px-2'
      >
        <div className='flex flex-col justify-center items-center max-w-[380px] w-full'>
          <div className='w-full flex justify-between items-center'>
            <Button className='bg-transparent border-none text-[#A8BDC1] rounded-[42px] p-4 hover:bg-[#e8f3ff] hover:text-[#7e5aeb]' icon={<ArrowLeftOutlined />} onClick={() => navigate('/login-new')} />
            <Typography.Text className='w-full mr-8 font-urbanist text-center text-3xl text-[#0F0F1C] font-semibold'>{verifiedForgetPassEmail ? "Enter New Password" : "Forget Password"}</Typography.Text>
          </div>
          <Typography.Text className='m-4 font-urbanist text-center leading-4 text-[12px] text-normal text-[#5d6769] mobile-m:text-sm'>
            {verifiedForgetPassEmail ? "Please enter Password and Verification code" : "Please enter your email address"}
          </Typography.Text>

          <Row gutter={[12, 20]} className='w-full px-3 xs:px-2 sm:px-1 md:px-0 lg:!gap-y-5' wrap align={"middle"} justify={"center"}>
            {verifiedForgetPassEmail ? (
              <>
                <Col span={24} className='mb-2'>
                  <Form.Item name='forget_password' rules={(!isResetCode) ? [{ required: true, message: '*Password is Required' }, passwordPatternValidation()] : []} validateFirst>
                    <FloatInput name={'forget_password'} type='password' placeholder={"Password *"} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name='verificationCode' rules={(!isResetCode) ? [{ required: true, message: '*Code is Required' }, { pattern: /^\d{6}$/, message: 'Code must be a 6-digit number.' }] : []}>
                    <FloatInput name={'verificationCode'} type='text' placeholder={"Enter Verification Code *"} maxLength={6} onChange={(e: React.ChangeEvent<HTMLInputElement>) => allowNumOnly(e, form, "verificationCode")} />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <Col span={24}>
                <Form.Item name='forget_email' rules={[{ required: true, message: '*Email Address is Required' }, emailPattenValidation()]} validateFirst>
                  <FloatInput name={'forget_email'} type='text' placeholder={"Email Address *"} />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row className='w-full'>
            <Col span={24} className='my-3 mb-6 px-4 flex justify-center items-center w-full md:px-2 lg:px-1 lg:mt-5'>
              {!!(verifiedForgetPassEmail) && (
                <>
                  {verifiedForgetPassEmail && count ? <Typography.Text className='block w-full font-urbanist font-semibold text-[#7E5AE2]'>Send code in {count}</Typography.Text> : <Button
                    className='font-urbanist text-[#7e5ae2] font-semibold' type='link' block onClick={handleResetCode}>Resend OTP</Button>}
                </>
              )}
              <Button
                htmlType='submit'
                className='bg-[#7E5AE2] text-white border-none w-full font-semibold rounded-md text-sm'
                loading={isApiLoading}
                iconPosition='end'
              >
                {verifiedForgetPassEmail ? "Submit" : "Send Code"}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default ForgetPasswordV1;
