import { Button, Form, Popover, Select, Tooltip, Typography } from 'antd'
import FloatInput from '../../components/FloatInput/floatInput';
import { useForm } from '../../utils/hooks/useForm';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { useEffect, useState } from 'react';
import { allowNumOnly, emailPattenValidation, phoneNumberLength, useAsyncValidate, validateDeaPattern, validateExactLength, validateMaxValue, validateNPIPattern } from '../../utils/methods/form-validations';
import './createUser.css';
import { genderStatus } from '../../config/constants';
import { StoreValue } from 'antd/es/form/interface';
import useDashboard from './hooks/useDashboard';
import { IUserDetails } from '../../types/global.types';
import { calculateFontSize, checkPhoneNumber, getProfileInitials } from '../../utils/methods/util-methods';

const AdvisoryBoardNew = () => {
  const { form } = useForm();
  const { checkDeaError, isDeaLoading } = useAsyncValidate();
  const { isApiLoading, updateUserDetails, verifyPhoneonUpdate, getUserDetails, initialValues, disabledDeaNumber, setDisabledDeaNumber, showPlaceholder, setShowPlaceholder } = useDashboard(form);
  const [countryData, setCountryData] = useState<CountryData | undefined>(undefined);
  const [isFieldChanged, setIsFieldChanged] = useState<boolean>(true);
  const userProfileDetail = getProfileInitials(initialValues?.board_firstName ?? "", initialValues?.board_lastName ?? "");

  useEffect(() => {
    getUserDetails();
  }, []);

  const validatePhone = (_: string, countryData: CountryData) => {
    const phoneValue = form.getFieldValue("board_phonenumber");
    setShowPlaceholder(phoneValue.length < 2);
    setCountryData(countryData);
  };

  const onFinish = async (values: StoreValue) => {
    if (values.board_deaNumber !== initialValues?.board_deaNumber) {
      const isValid = await checkDeaError(values.board_deaNumber, form, true)
      if (!isValid) return;
    }
    if (values.board_phonenumber !== initialValues?.board_phonenumber) {
      verifyPhoneonUpdate(values);
    }
    else {
      updateUserDetails(values);
      setIsFieldChanged(true);
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log({ errorInfo });
  }

  const handleFieldsChange = (_: StoreValue, values: StoreValue) => {
    const { id, ...rest } = initialValues as IUserDetails;
    const checkInitials = rest;
    const checkValues = { ...values, board_message_freq: +values.board_message_freq, board_phonenumber: checkPhoneNumber(checkInitials.board_phonenumber, values.board_phonenumber) }
    setIsFieldChanged(JSON.stringify(checkValues) === JSON.stringify(checkInitials));
    const IsDeaDisabled = !!initialValues?.board_deaNumber && (values.board_lastName === initialValues.board_lastName);
    setDisabledDeaNumber(IsDeaDisabled);
    console.log({ checkInitials, checkValues })
  }


  return (
    <div className='flex flex-col w-full my-8 lg:mb-auto lg:mt-16 gap-y-5 mx-5 mobile-m:mx-10 sm:mx-7 md:mx-8 md:gap-y-20 lg:mx-10 xl:mx-14 max-w-[1100px] 2xl:mx-auto'>
      <div className='flex justify-between items-center w-full'>
        <div className='flex flex-col items-center justify-center gap-y-2 mx-auto max-w-[340px] sm:max-w-full sm:mx-0 sm:gap-x-2'>
          <Typography.Title className='font-urbanist font-bold mb-0 text-3xl md:mr-auto'>Personal Information</Typography.Title>
          <Typography.Text className='font-urbanist leading-snug text-[#5d6769] text-sm text-center md:text-justify max-w-fit'>Ensure your profile reflects the latest details by completing the form below to update your information</Typography.Text>
        </div>
        <div className='user-profile-dp'>
          <Tooltip className='hidden lg:block' title={`${initialValues?.board_firstName || ''} ${initialValues?.board_lastName || ''}`.trim()}>
            <Typography.Text className={`hidden lg:inline-block font-urbanist font-bold mb-0`} style={{ fontSize: calculateFontSize(`${initialValues?.board_firstName} ${initialValues?.board_lastName}`), whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "200px" }}>{initialValues?.board_firstName} {initialValues?.board_lastName}</Typography.Text>
          </Tooltip>
          <Popover className='hidden md:block' placement='bottom' content={<span className='font-bold'>{`${initialValues?.board_firstName} ${initialValues?.board_lastName}`}</span>}>
            {userProfileDetail.initials && <div className={`hidden md:flex md:w-full md:max-w-14 md:justify-end md:items-center md:bg-[#7F5BE2] rounded-full`}>
              <div
                className="text-white w-14 h-14 flex items-center justify-center font-bold text-lg"
              >
                {userProfileDetail.initials}
              </div>
            </div>}
          </Popover>
        </div>
      </div>
      <Form className='bg-white border-none rounded-xl dashboard_form lg:rounded-[2rem]' form={form} onFinish={onFinish} onValuesChange={handleFieldsChange} onFinishFailed={onFinishFailed}>
        <Form.Item name={"board_firstName"} rules={[{ required: true, message: '*First Name is Required' }]}>
          <FloatInput name={"board_firstName"} placeholder={"First Name *"} required type="text" />
        </Form.Item>
        <Form.Item name={"board_lastName"} rules={[{ required: true, message: '*Last Name is Required' }]}>
          <FloatInput name={"board_lastName"} placeholder={"Last Name *"} required type="text" />
        </Form.Item>
        <Form.Item name='board_email' rules={[{ required: true, message: '*Email Address is Required' }, emailPattenValidation()]} validateFirst>
          <FloatInput name={'board_email'} type='text' placeholder={"Email Address *"} disabled />
        </Form.Item>
        <div className='mobile-div relative'>
          <span
            className={`absolute top-1/2 pl-[4.5rem] ${form.isFieldTouched("board_phonenumber") ? "-translate-y-[75%] md:-translate-y-[55%] lg:-translate-y-[61%]" : "-translate-y-[50%]"}  text-[#807c87] pointer-events-none ${showPlaceholder ? 'z-10' : '-z-10'} text-sm`}>
            Mobile Phone Number*
          </span>
          <Form.Item name='board_phonenumber' rules={[{ required: true, message: '*Mobile Number is Required' }, phoneNumberLength(countryData)]} validateFirst>
            <PhoneInput
              country={'us'}
              disableDropdown
              onlyCountries={['us']}
              countryCodeEditable={false}
              onChange={validatePhone}
              enableLongNumbers={(countryData as CountryData)?.format.split(".").length - 1 < 15 ? false : true}
            />
          </Form.Item>
        </div>
        <Form.Item name={"board_npiNumber"} rules={[{ required: true, message: '*NPI is Required' }, validateNPIPattern(), validateExactLength("NPI", 10), validateMaxValue("NPI", 2147483647)]} validateFirst>
          <FloatInput name={"board_npiNumber"} placeholder={"NPI Number *"} required type="text" maxLength={10} disabled />
        </Form.Item>
        <Form.Item name={"board_address"} rules={[{ required: true, message: '*Address is Required' }]}>
          <FloatInput name={"board_address"} placeholder={"Street Address *"} required type="text" />
        </Form.Item>
        <Form.Item className='pb-2' id='deaNumber_Item' name='board_deaNumber' rules={[validateDeaPattern()]} validateFirst initialValue={""}>
          <FloatInput name={'board_deaNumber'} type='text' placeholder={"DEA Number"} maxLength={9} disabled={disabledDeaNumber} className='disabled:bg-[#ededed] disabled:opacity-40' onChange={async (e: React.ChangeEvent<HTMLInputElement>) => await checkDeaError(e.target.value, form, false)} />
        </Form.Item>
        <Form.Item name={"board_state"} rules={[{ required: true, message: '*State is Required' }]}>
          <FloatInput name={"board_state"} placeholder={"State *"} required type="text" />
        </Form.Item>
        <Form.Item name={"board_city"} rules={[{ required: true, message: '*City is Required' }]}>
          <FloatInput name={"board_city"} placeholder={"City *"} required type="text" />
        </Form.Item>
        <Form.Item name='board_zipCode' rules={[{ required: true, message: '*Zip Code is Required' }, { pattern: /^\d{5}$/, message: 'Zip Code must be of 5 digits' }]} validateFirst>
          <FloatInput name={'board_zipCode'} type='text' placeholder={"Zip Code *"} maxLength={5} onChange={(e: React.ChangeEvent<HTMLInputElement>) => allowNumOnly(e, form, "board_zipCode")} />
        </Form.Item>
        <Form.Item name="board_gender" rules={[{ required: true, message: '*Gender is Required' }]}>
          <FloatInput
            type='select'
            name="board_gender"
            placeholder="*Gender"
            selectOptions={genderStatus.map((gender) => (
              <Select.Option key={gender.id} value={gender.name}>
                {gender.name}
              </Select.Option>
            ))}
          />
        </Form.Item>
        <Form.Item name={"board_message_freq"} rules={[{
          pattern: /^[0-9]{0,1}$/, // Only allows empty string or a single digit (0-9)
          message: 'Please enter a valid digit (0-9).',
        },]}>
          <FloatInput name={"board_message_freq"} maxLength={1} placeholder={"Days between messages"} type="text" popupText={<span className='text-[10px] text-[#5d6769] tracking-tight mobile-lg:text-xs mobile-lg:tracking-normal'>How often would you like to receive messages from us?</span>} />
        </Form.Item>
        <div className='flex w-full'>
          <Button
            htmlType='submit'
            className='mt-6 font-urbanist font-bold bg-[#7E5AE2] text-white border-none w-full rounded-md text-sm max-w-[290px] mx-auto disabled:opacity-45 xs:mt-3 sm:mt-7 md:text-base'
            iconPosition='end'
            disabled={isFieldChanged}
            loading={isDeaLoading || isApiLoading}
          >
            Update User Info
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default AdvisoryBoardNew