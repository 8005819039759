import { SetStateAction, useCallback, useState } from "react";
import useMakeApiRequest from "../../../api/useMakeApiRequest";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { capitalizeFirstLetter } from "../../../utils/methods/capitalize";
import { IUserDetails } from "../../../types/global.types";
import { StoreValue } from "antd/es/form/interface";
import { useNavigate } from "react-router-dom";
import { userDetailActions } from "../../../store/reducers/userDetailReducer";
import { FormInstance, message } from "antd";

const useDashboard = (form?: FormInstance) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleApiRequest, makeApiRequest, isApiLoading } =
    useMakeApiRequest();
  const authUser = useSelector((state: RootState) => state.auth.authUser);
  const [initialValues, setInitialValues] = useState<IUserDetails | null>(null);
  const [disabledDeaNumber, setDisabledDeaNumber] = useState<boolean>(false);
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);

  const getUserDetails = () => {
    handleApiRequest(
      () => makeApiRequest(`/user/details/${authUser?.email}`, "GET"),
      (response: any) => {
        if (response?.status === 200) {
          const userDetails = response.data;
          let updateFields = {
            id: userDetails.id,
            board_firstName: userDetails.firstName,
            board_lastName: userDetails.lastName,
            board_email: userDetails.email,
            board_phonenumber: userDetails.phoneNumber,
            board_npiNumber: `${userDetails.npiNumber}`,
            board_address: userDetails.streetAddress,
            board_deaNumber: userDetails?.dea_number ?? "",
            board_state: userDetails.state,
            board_city: userDetails.city,
            board_zipCode: userDetails.zipCode,
            board_gender: capitalizeFirstLetter(userDetails.gender),
            board_message_freq: Number(userDetails.messagingFrequency) ?? 0,
          };

          setInitialValues(updateFields);
          setDisabledDeaNumber(!!updateFields.board_deaNumber);
          setShowPlaceholder(false);
          if (form) {
            form.setFieldsValue(updateFields);
          }
        }
      },
      "Unable to fetch User Data!",
      false
    );
  };

  const verifyPhoneonUpdate = (values: StoreValue) => {
    handleApiRequest(
      () =>
        makeApiRequest(
          "/user/resend-phone-verification",
          "POST",
          {},
          {
            userName: values.board_email ?? authUser?.email,
            phoneNumber: `+${values.board_phonenumber}`,
          }
        ),
      (response: any) => {
        if (response.status === 200) {
          message.success("Phone verification code sent successfully");
          navigate("/verify-otp", {
            state: {
              email: values.board_email,
              password: authUser?.password,
              phoneNumber: values.board_phonenumber,
              updatingUserPhone: true,
            },
          });
          dispatch(
            userDetailActions.setUserDetail({
              ...values,
              id: form?.getFieldValue("id"),
            })
          );
        }
      },
      "Unable to Make Phone Verify Request!",
      true
    );
  };

  const updateUserDetails = (
    values: StoreValue,
    disableDeaNumber?: React.Dispatch<SetStateAction<boolean>>
  ) => {
    const payload = {
      id: form?.getFieldValue("id") ?? values?.id,
      firstName: values.board_firstName,
      lastName: values.board_lastName,
      city: values.board_city,
      email: values.board_email,
      gender: values.board_gender,
      messagingFrequency: +values.board_message_freq,
      npiNumber: +values.board_npiNumber,
      phoneNumber: values.board_phonenumber,
      state: values.board_state,
      streetAddress: values.board_address,
      zipCode: values.board_zipCode,
      deaNumber: values.board_deaNumber,
    };
    handleApiRequest(
      () => makeApiRequest(`/user/details`, "PUT", {}, { ...payload }),
      (response: any) => {
        if (response.status === 200) {
          message.success(response.message);
        }
        getUserDetails();
      },
      "Unable to Update Record",
      true
    );
  };

  return {
    getUserDetails,
    isApiLoading,
    updateUserDetails,
    initialValues,
    verifyPhoneonUpdate,
    disabledDeaNumber,
    setDisabledDeaNumber,
    showPlaceholder,
    setShowPlaceholder,
  };
};

export default useDashboard;
